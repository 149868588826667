"use client";
import { Spoiler } from "@mantine/core";

interface TextColorsProps {
    text?: string;
    wordsInDifferentColor?: string[];
    textColor?: string;
    differentTextColor?: string;
    textSizes?: string;
    paddings?: string;
    hasReadMore?: boolean;
}

export default function TextColors({
    text = "Immer schon auf Forschungskurs: das Warum ist Teil meiner DNA",
    wordsInDifferentColor = ["das:", "Warum", "DNA"],
    textColor = "text-black",
    differentTextColor = "text-yellow",
    textSizes = "text-center text-5xl  lg:text-8xl  leading-14 lg:leading-70",
    paddings = "",
    hasReadMore = false,
}: TextColorsProps) {
    const words = text.split(" ");
    return (
        <div className={`${paddings}`}>
            {hasReadMore ? (
                <Spoiler
                    maxHeight={198}
                    showLabel="Mehr lesen"
                    hideLabel="  Wenige lesen"
                    color="white"
                >
                    <div>
                        {words.map((word, index) => (
                            <span
                                className={`${
                                    wordsInDifferentColor.includes(word)
                                        ? differentTextColor
                                        : textColor
                                } ${textSizes}`}
                                // biome-ignore lint/suspicious/noArrayIndexKey: words can be repeated, and the order is important
                                key={index}
                            >
                                {`${word.trim()} `}
                            </span>
                        ))}
                    </div>
                </Spoiler>
            ) : (
                <>
                    {words.map((word, index) => {
                        return (
                            <span
                                // biome-ignore lint/suspicious/noArrayIndexKey: words can be repeated, and the order is important
                                key={index}
                                className={`${
                                    wordsInDifferentColor.includes(word)
                                        ? differentTextColor
                                        : textColor
                                } ${textSizes}`}
                            >
                                {`${word} `}
                            </span>
                        );
                    })}
                </>
            )}
        </div>
    );
}
