"use client";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper } from "swiper/react";

interface SliderNoArrowsProps {
    slideContent?: string;
    contentEl?: JSX.Element;
    paginationColor?: string;
    slidesPerView?: number;
    centered?: boolean;
}
export default function SliderNoArrows({
    slideContent,
    contentEl,
    paginationColor = "#000",
    slidesPerView = 1,
    centered = true,
}: SliderNoArrowsProps) {
    const pagination = {
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3,
    };
    return (
        <>
            <Swiper
                modules={[Pagination, Navigation]}
                pagination={pagination}
                // autoHeight={true}
                style={{
                    //@ts-ignore
                    "--swiper-pagination-color": `${paginationColor}`,
                    "--swiper-pagination-bullet-inactive-color": `${paginationColor}`,
                }}
                autoplay={{
                    delay: 200,
                }}
                centeredSlides={centered}
                slidesPerView={slidesPerView}
                spaceBetween={0}
                navigation={true}
                className="mySwiper"
            >
                {contentEl}
            </Swiper>
        </>
    );
}
