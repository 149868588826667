"use client";
import Script from "next/script";
import type { GTMParams } from "@next/third-parties/dist/types/google";

// biome-ignore lint/suspicious/noExplicitAny: The dataLayer can be anything
export type GTMDataLayer = [string, string, any?][] | [string, any?][] | any[];

// Override the dataLayer type in GTMParams with our custom GTMDataLayer type
export type GTMParamsWithCustomDataLayer = Omit<GTMParams, "dataLayer"> & {
    dataLayer?: GTMDataLayer;
};

let currDataLayerName: string | undefined = undefined;

export function GoogleTagManager(props: GTMParamsWithCustomDataLayer) {
    const { gtmId, dataLayerName = "dataLayer", auth, preview, dataLayer } = props;

    if (currDataLayerName === undefined) {
        currDataLayerName = dataLayerName;
    }

    const gtmLayer = dataLayerName !== "dataLayer" ? `&l=${dataLayerName}` : "";
    const gtmAuth = auth ? `&gtm_auth=${auth}` : "";
    const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : "";

    return (
        <>
            <Script
                id="_next-gtm-init"
                // biome-ignore lint/security/noDangerouslySetInnerHtml:This is the only way to inject the GTM script
                dangerouslySetInnerHTML={{
                    __html: `
      (function(w,l){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        ${dataLayer ? `w[l].push(...${JSON.stringify(dataLayer)})` : ""}
      })(window,'${dataLayerName}');`,
                }}
            />
            <Script
                id="_next-gtm"
                data-ntpc="GTM"
                src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
            />
        </>
    );
}

// biome-ignore lint/suspicious/noExplicitAny: The dataLayer can be anything
export const sendGTMEvent = (data: any) => {
    if (currDataLayerName === undefined) {
        console.warn("@next/third-parties: GTM has not been initialized");
        return;
    }

    if (window[currDataLayerName]) {
        if (Array.isArray(data)) {
            window[currDataLayerName].push(...data);
        } else {
            window[currDataLayerName].push(data);
        }
    } else {
        console.warn(
            `@next/third-parties: GTM dataLayer ${currDataLayerName} does not exist`,
        );
    }

    return window[currDataLayerName];
};
