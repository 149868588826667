import { twMerge } from "tailwind-merge";

interface TrianglesProps {
    backgroundColor: string;
    buttomToTop?: boolean;
}

export default function Triangles({ backgroundColor, buttomToTop }: TrianglesProps) {
    return (
        <div className="-mb-[0.1rem]">
            {buttomToTop ? (
                <>
                    <div className={twMerge("top", backgroundColor)} />
                </>
            ) : (
                <>
                    <div className={twMerge("bottom", backgroundColor)} />
                </>
            )}
        </div>
    );
}
