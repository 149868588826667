import Image from "next/image";
import { twMerge } from "tailwind-merge";
import TextColors from "./TextColors";

interface TestimonialProps {
    text?: string;
    icon?: string;
    name?: string;
    description?: string;
    hasDescription?: boolean;
    wordsInDifferentColor?: string[];
    textColor?: string;
    backgroundColor?: string;
    differentTextColor?: string;
    hasImage?: boolean;
    textSizes?: string;
    paddings?: string;
    hasReadMore?: boolean;
}
export default function Testimonial({
    text = "Ich arbeite sehr exakt, denn Menschen sind etwas Kostbares!",
    icon = "/Icons/quoteIconBlack.svg",
    name = "Rico Brunner",
    description = "Energiefeldmechaniker seit über 25 Jahren",
    hasDescription = true,
    wordsInDifferentColor = ["Menschen", "sind", "etwas", "Kostbares!"],
    textColor = "text-black",
    backgroundColor = "bg-turquoise",
    differentTextColor = "text-yellow",
    hasImage = false,
    textSizes = "lg:text-5xl  text-xl3  font-bold  leading-10 lg:leading-14",
    paddings = "px-6 py-25 lg:px-56 lg:py-25 md:px-28",
    hasReadMore = false,
}: TestimonialProps) {
    const words = name.split(",");

    const images = [
        {
            url: "/images/testimonials/MichaelaWaltherGunzburg.jpg",
            name: "Michaela Walther Günzburg",
        },
        {
            url: "/images/testimonials/MyriamRichle.jpg",
            name: "Myriam Richle",
        },
        {
            url: "/images/testimonials/Sabine.jpeg",
            name: "Sabine Bühlmann Pilotin,  Wadersloh Deutschland, Bild Copyright: Rasmus Schübel",
        },
        {
            url: "/images/testimonials/SteffiLange.jpg",
            name: "Steffi Lange, 50 Jahre, Zahntechnikerin Iffeldorf, Deutschland",
        },
        {
            url: "/images/testimonials/CarmelaPlaca.jpg",
            name: "Carmela Placa",
        },
    ];
    return (
        <>
            <div
                className={twMerge(
                    "flex-col justify-center items-center gap-1 inline-flex",
                    backgroundColor,
                    paddings,
                )}
            >
                <div className="flex-col justify-center items-start gap-5 flex">
                    <Image alt="" height={70} width={70} src={icon} />
                    <div className="self-stretch">
                        <TextColors
                            differentTextColor={differentTextColor}
                            textColor={textColor}
                            wordsInDifferentColor={wordsInDifferentColor}
                            textSizes={textSizes}
                            text={text}
                            hasReadMore={hasReadMore}
                        />
                    </div>
                    <div className="self-stretch h-17 pt-5 flex-col justify-center items-start gap-1 flex">
                        <div className="self-stretch h-17 flex-col justify-center items-start gap-1 flex">
                            <div className="flex gap-4">
                                {hasImage && (
                                    <Image
                                        width={100}
                                        height={100}
                                        alt=""
                                        objectFit="cover"
                                        className="w-15 h-15 rounded-7xl"
                                        src={
                                            images.filter(
                                                (image) => image.name === name,
                                            )?.[0]?.url
                                        }
                                        style={{ objectFit: "cover" }}
                                    />
                                )}
                                <div
                                    className={twMerge(
                                        "self-stretch flex flex-col space-y-2 text-xl lg:text-2xl font-bold  leading-7 lg:leading-5",
                                        textColor,
                                    )}
                                >
                                    {words.map((word) => (
                                        <p key={word}>{word.trim()}</p>
                                    ))}
                                </div>
                            </div>
                            {hasDescription && (
                                <div
                                    className={twMerge(
                                        "text-lg lg:text-xl1 font-normal  leading-7 lg:leading-5",
                                        textColor,
                                    )}
                                >
                                    {description}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
